import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Multiselect from "react-select";
import subscriptionService from "../../../../services/subscription.service.js";
import { useSnackbar } from "notistack";
import moment from "moment";

export function SubscriptionModel(props) {
    const {
        formik,
        openCity,
        handleOpenCity,
        handleCloseCity,
        update,
        loader,
        checkStatus,
        changePeriod,
        changePlan,
        startDateChange,
        endDateChange,
        bottomSpace,
        planType
    } = props;

    const planOption = [
        { label: 'Normal', value: 'normal' },
        { label: 'Limited Offer', value: 'limited_offer' }
    ]

    const periodOptions = [
        { label: 'Days', value: 'days' },
        { label: 'Month', value: 'month' },
        { label: 'Year', value: 'year' }
    ]

    return (
        <Modal
            {...props}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton onClick={formik.resetForm}><div style={{ width: '100%', marginLeft: '10px', textAlign: 'center' }}> <h4 className="m-0">{update ? "Update Subscription Plan" : "Create Subscription Plan"}</h4></div></Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="col-12">
                                    <Form.Group
                                        controlid="validationFormik101"
                                        className="position-relative"
                                    >
                                        <Form.Label
                                            style={{
                                                textAlign: "start",
                                                color: "#000",
                                                marginTop: "15px",
                                            }}
                                        >
                                            Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.name && formik.touched.name ? (
                                            <p
                                                classtype="text-start error"
                                                style={{ color: "red", textAlign: "start" }}
                                            >
                                                {formik.errors.name}
                                            </p>
                                        ) : null}
                                        <Form.Label
                                            style={{
                                                textAlign: "start",
                                                color: "#000",
                                                marginTop: "15px",
                                            }}
                                        >
                                            Description
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            placeholder="Description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.description && formik.touched.description ? (
                                            <p
                                                classtype="text-start error"
                                                style={{ color: "red", textAlign: "start" }}
                                            >
                                                {formik.errors.description}
                                            </p>
                                        ) : null}
                                        <Box sx={{ display: "flex" }}>
                                            <div
                                                style={{
                                                    display: "block",
                                                    width: "45%",
                                                }}
                                            >
                                                <Form.Label
                                                    style={{
                                                        textAlign: "start",
                                                        color: "#000",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    Select Plan
                                                </Form.Label>
                                                <Multiselect
                                                    classNamePrefix="Select2 z-index-10"
                                                    options={planOption}
                                                    singleSelect
                                                    name="plan_type"
                                                    onChange={changePlan}
                                                    isSearchable
                                                    menuIsOpen={openCity}
                                                    onMenuOpen={handleOpenCity}
                                                    onMenuClose={handleCloseCity}
                                                    value={planOption.find(
                                                        (option) =>
                                                            option.value === formik.values.plan_type
                                                    )}
                                                />
                                                {formik.errors.plan_type &&
                                                    formik.touched.plan_type ? (
                                                    <p
                                                        classtype="text-start error"
                                                        style={{ color: "red", textAlign: "start" }}
                                                    >
                                                        {formik.errors.plan_type}
                                                    </p>
                                                ) : null}
                                            </div>
                                            {planType === 'normal' &&
                                                <div
                                                    style={{
                                                        display: "block",
                                                        width: "45%",
                                                        marginLeft: "auto",
                                                    }}
                                                >
                                                    <Form.Label
                                                        style={{
                                                            textAlign: "start",
                                                            color: "#000",
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        Select Period
                                                    </Form.Label>
                                                    <Multiselect
                                                        classNamePrefix="Select2 z-index-10"
                                                        options={periodOptions}
                                                        singleSelect
                                                        name="period_type"
                                                        isDisabled={
                                                            checkStatus === "finished" ||
                                                            checkStatus === "active"
                                                        }
                                                        onChange={changePeriod}
                                                        isSearchable
                                                        menuIsOpen={openCity}
                                                        onMenuOpen={handleOpenCity}
                                                        onMenuClose={handleCloseCity}
                                                        value={periodOptions.find(
                                                            (option) =>
                                                                option.value === formik.values.period_type
                                                        )}
                                                    />
                                                    {formik.errors.period_type &&
                                                        formik.touched.period_type ? (
                                                        <p
                                                            classtype="text-start error"
                                                            style={{ color: "red", textAlign: "start" }}
                                                        >
                                                            {formik.errors.period_type}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            }
                                        </Box>
                                        {formik.values.plan_type === 'limited_offer' &&
                                            <>
                                                <div style={{ display: 'flex', marginBottom: bottomSpace && '10px' }}>
                                                    <div style={{
                                                        display: "block",
                                                        width: "45%",
                                                    }}>
                                                        <Form.Label
                                                            style={{
                                                                textAlign: "start",
                                                                color: "#000",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            Start Date
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="start_date"
                                                            placeholder="Price"
                                                            value={moment((formik.values.start_date)).format("YYYY-MM-DD")}
                                                            onChange={startDateChange}
                                                        />
                                                        {formik.errors.start_date && formik.touched.start_date ? (
                                                            <p
                                                                classtype="text-start error"
                                                                style={{ color: "red", textAlign: "start" }}
                                                            >
                                                                {formik.errors.start_date}  
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div style={{
                                                        marginLeft: 'auto',
                                                        display: "block",
                                                        width: "45%",
                                                    }}>
                                                        <Form.Label
                                                            style={{
                                                                textAlign: "start",
                                                                color: "#000",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            End Date
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="exp_date"
                                                            placeholder="Price"
                                                            value={moment((formik.values.exp_date)).format("YYYY-MM-DD")}
                                                            onChange={endDateChange}
                                                        />
                                                        {formik.errors.exp_date && formik.touched.exp_date ? (
                                                            <p
                                                                classtype="text-start error"
                                                                style={{ color: "red", textAlign: "start" }}
                                                            >
                                                                {formik.errors.exp_date}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div style={{
                                            display: "block",
                                            width: "45%",
                                        }}>
                                            <Form.Label
                                                style={{
                                                    textAlign: "start",
                                                    color: "#000",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                Price($)
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="price"
                                                placeholder="Price $"
                                                value={formik.values.price}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.errors.price && formik.touched.price ? (
                                                <p
                                                    classtype="text-start error"
                                                    style={{ color: "red", textAlign: "start" }}
                                                >
                                                    {formik.errors.price}
                                                </p>
                                            ) : null}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        style={{
                                            display: "flex",
                                            marginLeft: "auto",
                                            marginRight: "15px",
                                            marginTop: "15px",
                                        }}
                                        className="btn btn-primary"
                                    >
                                        {loader ? <CircularProgress size={20} style={{ textAlign: "center", color: '#fff' }} /> :
                                            update ? "Update" : "Submit"
                                        }
                                    </button>
                                </div>
                            </form>
                        </>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}


export const DeleteSubscription = (props) => {
    const { onHide, id, name, getAllResult } = props;
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const deleteRace = async () => {
        setLoader(true);
        await subscriptionService.deleteSubscription(id);
        onHide();
        enqueueSnackbar("Delete Subscription Successfully", { variant: "success" });
        getAllResult();
        setLoader(false);
    };
    return (
        <Modal
            {...props}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            {/* <Modal.Header closeButton /> */}
            <Modal.Header closeButton><div style={{ width: '100%', marginLeft: '10px', textAlign: 'center' }}> <h4 className="m-0">{"Delete Subscription Plan"}</h4></div></Modal.Header>
            <Modal.Body className="text-center">
                <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>
                <h4 className="tx-danger tx-semibold mg-b-20">Delete !</h4>
                <p className="mg-b-20 mg-x-20 ">
                    are you sure want to delete {name} subscription !!
                </p>
                <div>
                    <Button variant="danger" onClick={deleteRace} style={{ display: 'flex', marginLeft: 'auto' }}>
                        {loader ? <CircularProgress size={20} style={{ textAlign: "center", color: '#fff' }} /> : "Delete"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
