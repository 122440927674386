import serverCall from "../serverCall";

const getAllCode = async (pagenumber, pagesize, filter) => {
    const response = await serverCall.get(
        `/promocode/get-all-promocodes?page=${pagenumber}&limit=${pagesize}&filter=${filter}`
    );
    return response;
};

const generateCode = async () => {
    const response = await serverCall.post(`/promocode/generate-promocodes?numberOfPromoCode=100`);
    return response;
};

const PromoCodeService = {
    getAllCode,
    generateCode,
};

export default PromoCodeService;
