export const MENUITEMS = [
  {
    Items: [
      {
        title: "Dashboard",
        path: `/dashboard`,
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
      },
      {
        title: "Subscription Plan",
        path: `/subscription`,
        icon: "ti-wallet",
        type: "link",
        active: false,
        selected: false,
      },
      {
        title: "Deleted Users",
        path: `/deleted-account`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
      },
      {
        title: "Promo Code",
        path: `/promo-code`,
        icon: "ti-bolt",
        type: "link",
        active: false,
        selected: false,
      },
    ],
  },
];
