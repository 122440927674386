import { CircularProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataTable from "react-data-table-component";


export const PromocodeTable = (props) => {
    const {
        data,
        totalRows,
        dataPerPage,
        handlePageChange,
        handlePerPageChange,
        isLoading,
        pageNumber,
    } = props;

    const [showTooltip, setShowTooltip] = useState({})
    const copyCode = (code, id) => {
        navigator.clipboard.writeText(code);
        setShowTooltip((prev) => ({
            ...prev,
            [id]: true
        }));
        setTimeout(() => {
            setShowTooltip((prev) => ({
                ...prev,
                [id]: false
            }));
        }, 1500);
    };

    const columns = [
        {
            name: "PROMO CODE",
            selector: row => <div className="d-flex justify-content-center tooltip-code" style={{ maxWidth: "250px", minWidth: '250px' }} title={row.promocode}>{row.promocode}
                <div className="ps-2 ms-auto" style={{ cursor: "pointer" }} onClick={() => copyCode(row.promocode, row._id)}>{<ContentCopyIcon />}</div>
                <span className={`${showTooltip[row._id] ? "tooltiptext-code" : ""}`}>
                    {showTooltip[row._id] && "Copied"}
                </span>
            </div>,
            sortable: true,
            width: '500px'
        },
        {
            name: "USE",
            selector: row => <div title={row.isUsed}>{row.isUsed === true ? "Used" : "Not Used"}</div>,
            sortable: true,
            width: '200px'
        },
    ];

    const extensions = useMemo(
        () => ({
            filter: true,
        }),
        []
    );


    return (
        <>
            <div lg={12} className="w-100">

                <Card className="custom-card overflow-hidden">
                    <Card.Body className="pb-0">
                        <div className="responsive">
                            <Card className="custom-card overflow-hidden">
                                <div>
                                    <div style={{ marginTop: '10px ' }}>
                                    </div>
                                    <DataTable
                                        {...extensions}
                                        data={data}
                                        columns={columns}
                                        noHeader
                                        persistTableHead
                                        pagination
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                        highlightOnHover
                                        center={true}
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationRowsPerPageOptions={[5, 10, 50, 100]}
                                        paginationPerPage={dataPerPage}
                                        paginationDefaultPage={pageNumber}
                                        onChangeRowsPerPage={(page) => {
                                            handlePerPageChange(page, 1);
                                        }}
                                        onChangePage={handlePageChange}
                                        progressPending={isLoading}
                                        cu
                                        progressComponent={
                                            <CircularProgress
                                                size={30}
                                                style={{ marginTop: "50px" }}
                                            />
                                        }
                                    />
                                </div>
                            </Card>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};
