import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "../app/pages/components/Authentication/Login";
import MatxLayout from "../app/pages/components/maxLayout";
import NotFound from "../layouts/sessions/NotFound";
import { DashboardTable } from "../app/pages/components/ListOfUser/ListOfUser";
import { SessionTable } from "../app/pages/components/ListOfUser/sessionTable";
import { Subscription } from "../app/pages/components/subscription/subscription";
import { DeletedUser } from "../app/pages/components/deletedAccount/deletedUser";
import { PromoCode } from "../app/pages/components/promocode/promocode";


function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/dashboard/" />,
      },
      {
        path: "/dashboard/",
        element: <DashboardTable />,
      },
      {
        path: "/dashboard/:id",
        element: <SessionTable />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      {
        path: "/deleted-account",
        element: <DeletedUser />,
      },
      {
        path: "/promo-code",
        element: <PromoCode />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/dashboard/" />
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
