import axios from "axios";

import config from "./config";

const BASE_URL = config.baseApiURL;
const customAxios = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  const tokenjson = JSON.parse(localStorage.getItem("userProfile"));
  if (tokenjson?.token) {
    request.headers.Authorization = `Bearer ${tokenjson?.token}`;
  }
  // else if (localStorage.getItem('access-token')) {
  //     const accesstoken = JSON.parse(localStorage.getItem('access-token'));
  //     request.headers.Authorization = `Bearer ${accesstoken}`;
  // }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 400) {
    localStorage.clear()
    window.location = "/login";
  }
  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = async (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 400) {
      // Do something, call refreshToken() request for example;
      // return a request
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = "/login";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxios;
