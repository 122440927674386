import React from "react";
import { Card, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { DeletedUserTable } from "./deletedTable";
import DeleteUserService from "../../../../services/deletedusers.service";

export function DeletedUser() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [search, setSearch] = useState("");

  const getAllUserData = async () => {
    setIsLoading(true)
    const response = await DeleteUserService.getAllUser({
      search: search.toLowerCase() || "",
      page: pageNumber,
      limit: dataPerPage
    });
    setIsLoading(false)
    setTotalRows(response?.data.totalCount);
    setData(response?.data.usersData);
  };

  useEffect(() => {
    getAllUserData();
  }, [search, pageNumber, dataPerPage]);

  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await getAllUserData();
    setIsLoading(false);
  };

  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await getAllUserData();
    setIsLoading(false);
  };

  const handleData = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="page-header2"></div>
      <div className="row">
        <Col sm={12} md={6} lg={6} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-order ">
                <label className="main-content-label font-weight-bold mb-3 pt-1">
                  Total Deleted Users
                </label>
                <h2 className="text-end card-item-icon card-icon">
                  <i className="fa fa-user icon-size float-start text-primary"></i>
                  <span className="font-weight-bold">{totalRows}</span>
                </h2>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
      <DeletedUserTable 
        data={data}
        totalRows={totalRows}
        dataPerPage={dataPerPage}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        isLoading={isLoading}
        getAllUser={getAllUserData}
        handleData={handleData}
        pageNumber={pageNumber}
        search={search}
      />
    </>
  );
}
