import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { PromocodeTable } from "./promoTable";
import PromoCodeService from "../../../../services/promocode.service";
import { CircularProgress } from "@mui/material";
import Dropdown from 'react-select'



export function PromoCode() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState("");
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredValue, setFilteredValue] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    const getAllUserData = async () => {
        setIsLoading(true)
        const response = await PromoCodeService.getAllCode(
            pageNumber,
            dataPerPage,
            filteredValue
        );
        setIsLoading(false)
        setTotalRows(response?.data.totalPromocodes);
        setData(response?.data.data);
    };

    useEffect(() => {
        getAllUserData();
    }, [search, pageNumber, dataPerPage, filteredValue]);

    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        setIsLoading(true);
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };

    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };

    const handleData = (e) => {
        setSearch(e.target.value);
    };

    const submit = async () => {
        try {
            setLoader(true);
            await PromoCodeService.generateCode(100);
            getAllUserData();
            enqueueSnackbar("Generate promo-code successfully", { variant: "success" });
            setLoader(false);
        } catch (error) {
            console.error("create race error", error);
            setLoader(false);
        }
    };

    const Option = [
        { label: "All", value: "" },
        { label: "Used", value: "used" },
        { label: "Not Used", value: "unused" }
    ]

    const handleChangeOption = (e) => {
        setFilteredValue(e.value)
    }

    return (
        <>
            <div className="page-header2"></div>
            <div className="row">
                <div className="d-flex justify-content-end align-items-end mb-3  ps-0">
                    <div className="d-flex">
                        <Button className="text-white" style={{ marginRight: "15px" }} onClick={submit}>{loader ? <CircularProgress size={30}
                            style={{ color: "#fff", }} /> : "Generate Promo Code"}</Button>
                        <div style={{ maxWidth: "200px", minWidth: "170px", marginLeft: "auto" }}>
                            <Dropdown placeholder="Filter by" options={Option} onChange={(e) => handleChangeOption(e)} />
                        </div>
                    </div>
                </div>
                <PromocodeTable
                    data={data}
                    totalRows={totalRows}
                    dataPerPage={dataPerPage}
                    handlePerPageChange={handlePerPageChange}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    getAllUser={getAllUserData}
                    handleData={handleData}
                    pageNumber={pageNumber}
                    search={search}
                />
            </div>
        </>
    );
}
