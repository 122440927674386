import React, { Fragment } from "react";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { login, setLoggedIn } from "../../../../store/slice/auth";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };

  const schema = object({
    email: string().email().required(" Enter Your Email"),
    password: string().min(4).required(" Enter Your password"),
  });

  const submit = async (values, action) => {
    const { email, password } = values;
    try {
      const response = await dispatch(login({ email, password })).unwrap();
      dispatch(setLoggedIn(true));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      enqueueSnackbar("Login Successfully!", { variant: "success" });
      navigate(`/dashboard/`);
      action.resetForm();
    } catch (e) {
      navigate("/login");
      enqueueSnackbar("Invalid credentials ", { variant: "error" });
    }
  };

  const initialValue = {
    email: "",
    password: "",
  };

  const { errors, touched, handleSubmit, values, handleChange } = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: submit,
  });

  return (
    <>
      <Fragment>
        <div className="page main-signin-wrapper">
          <Row className="signpages text-center">
            <Col md={12}>
              <Card>
                <Row className="row-sm">
                  <Col
                    lg={6}
                    xl={5}
                    className="d-none d-lg-block text-center details"
                  // style={{ backgroundColor: '#192f53' }}
                  >
                    <div className="h-100 d-flex align-items-center justify-content-center w-100 pos-relative flex-column" style={{ zIndex: "999", }}>
                      <h3 style={{ color: "#FFFCFD" }}>syncbysonic</h3>
                      <div className="clearfix"></div>
                      <img
                        src={require("../../../../assets/syncbysonicimg/auto-logo.png")}
                        className="ht-100 mb-0"
                        alt="user"
                      />
                      <h3 className="mt-4 text-white">Sign In</h3>
                      {/* <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                        Signup to create, discover and connect with the AUTOBIOGRAPHY
                        community
                      </span> */}
                    </div>
                  </Col>
                  <Col lg={6} xl={7} xs={12} sm={12} className="login_form ">
                    <Container fluid>
                      <Row className="row-sm">
                        <Card.Body className="mt-2 mb-2">
                          <img
                            src={require("../../../../assets/syncbysonicimg/auto-logo.png")}
                            className="ht-70 p-2 d-lg-none header-brand-img text-start float-start mb-19 auth-light-logo"
                            alt="logo"
                          />
                          {/* <img
                            src={require("../../assets/img/brand/logo-light.png")}
                            className=" d-lg-none header-brand-img text-start float-start mb-4 auth-dark-logo"
                            alt="logo"
                          /> */}
                          <form onSubmit={handleSubmit}>
                            <h5 className="text-start mb-2">
                              {/* Signin to Your Account */}
                            </h5>
                            <p className="mb-4 text-muted tx-13 ms-0 text-start">
                              {/* Signin to create, discover and connect with the
                              global community */}
                            </p>
                            <Form.Group
                              className="text-start form-group mt-4"
                              controlId="formEmail"
                            >
                              <Form.Label style={{ marginTop: '15px' }}>Email</Form.Label>        {/* E-MAIL */}
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your email"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                              {errors.email && touched.email ? (
                                <p style={formerr}>{errors.email}</p>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="text-start form-group"
                              controlId="formpassword"
                            >
                              <Form.Label style={{ marginTop: '15px' }}>Password</Form.Label>     {/* PASS-WORD */}
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your password"
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                              {errors.password && touched.password ? (
                                <p style={formerr}>{errors.password}</p>
                              ) : null}
                            </Form.Group>
                            <Button
                              type="submit"
                              className="btn ripple btn-block mt-3"
                              style={{ backgroundColor: '#192f53'}}
                            >
                              Sign In
                            </Button>
                          </form>
                          <div className="text-start mt-4 ms-0">
                            <div className="mb-1">
                              {/* <Link to="#" > Forgot password ?</Link> */}
                            </div>
                          </div>
                        </Card.Body>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    </>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
