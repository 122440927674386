import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DeleteUserService from "../../../../services/deletedusers.service";

export const DeletedUserTable = (props) => {
    const { getAllUser, totalRows, data, dataPerPage, handlePageChange, handlePerPageChange, isLoading, handleData, search, pageNumber } = props
    const [userId, setUserId] = useState({});
    const [modelShow, setModelShow] = useState(false);
    const handleClose = () => setModelShow(false)

    const handleOpen = (row) => {
        setUserId(row)
        setModelShow(true)
    }
    const columns = [
        {
            name: "EMAIL",
            selector: row => <div title={row.email}>{row.email}</div>,
            sortable: true,
        },
        {
            name: "FIRST NAME",
            selector: row => <div title={row.first_name}>{row.first_name}</div>,
            sortable: true
        },
        {
            name: "LAST NAME",
            selector: row => <div title={row.last_name}>{row.last_name}</div>,
            sortable: true,
        },
        {
            name: "PHONE NUMBER",
            selector: row => <div title={row.mobile}>{row.mobile}</div>,
            sortable: true,
        },
        {
            name: "CREATION DATE",
            selector: row => <div title={moment(row.createdAt).format('DD-MM-YYYY')}>{moment(row.createdAt).format('DD-MM-YYYY')}</div>,
            sortable: true,
        },
        {
            name: "ACTIONS",
            sortable: true,
            selector: row => <button className="btn btn-primary btn-sm" onClick={() => handleOpen(row)}>Re-Active</button>
        }
    ];

    const extensions = useMemo(
        () => ({
            filter: true,
        }),
        []
    );


    return (
        <>
            <div className="page-header2"></div>
            <Card className="custom-card overflow-hidden">
                <Card.Body className="pt-3">
                    <div className="responsive">
                        <Card className="custom-card overflow-hidden">
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', }}>
                                    <div className="align-items-end" style={{ paddingLeft: '0px', paddingRight: '0px', maxWidth: '200px', marginLeft: 'auto' }}>
                                        <div
                                            style={{
                                                justifyContent: "start",
                                                marginRight: "2px",
                                                marginTop: '15px'
                                            }}
                                        >
                                            <input
                                                type="search"
                                                style={{
                                                    border: "1px solid #e8e8f7",
                                                    padding: "0.6rem 0.75rem",
                                                    borderRadius: "7px",
                                                    width: '100%'
                                                }}
                                                value={search}
                                                placeholder="Filter Table"
                                                onChange={(e) => handleData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    {...extensions}
                                    data={data}
                                    columns={columns}
                                    noHeader
                                    persistTableHead
                                    pagination
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                    }}
                                    highlightOnHover
                                    center={true}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                                    paginationPerPage={dataPerPage}
                                    paginationDefaultPage={pageNumber}
                                    onChangeRowsPerPage={(page) => {
                                        handlePerPageChange(page, 1);
                                    }}
                                    onChangePage={handlePageChange}
                                    progressPending={isLoading}
                                    cu
                                    progressComponent={
                                        <CircularProgress
                                            size={30}
                                            style={{ marginTop: "50px" }}
                                        />
                                    }
                                />
                            </div>
                        </Card>
                    </div>
                </Card.Body>
            </Card>
            {modelShow && <ViewStory show={modelShow} getAllUser={getAllUser} onHide={handleClose} data={userId} />}
        </>
    );
};


const ViewStory = (props) => {
    const { data, onHide, getAllUser } = props;
    const [loader, setLoader] = useState(false)
    const handleSubmit = async () => {
        setLoader(true)
        await DeleteUserService.UserUpdate(data._id)
        setLoader(false)
        onHide();
        getAllUser()
    }
    return (
        <Modal
            {...props}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header
                closeButton
                style={{ textAlign: "center", fontWeight: "bold" }}
            >
                {data.name}
            </Modal.Header>
            <Modal.Body className="text-center">
                <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

                <h4 className="tx-danger tx-semibold mg-b-20">Reactive account</h4>
                <p className="mg-b-20 mg-x-20 ">
                    Are you sure want to reactive <b> {data.email}</b>'s account ?
                </p>
                <div className="d-flex justify-content-end">

                    <Button variant="primary" onClick={handleSubmit}>{loader ? <CircularProgress size={30} style={{ color: "#fff" }} /> : "Re active"}</Button>
                    <Button variant="danger" onClick={onHide} className="ms-3">Cancel</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};