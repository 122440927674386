import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../../layouts/Header/Header";
import Sidebar from "../../../layouts/SideBar/SideBar";
import Rightside from "../../../layouts/Rightside/Rightside";
import Footer from "../../../layouts/Footer/Footer";
const MatxLayout = () => {
  return (
    <Fragment>
      <div className="page">
        <Header />
        <Sidebar />
        <div className="main-content side-content">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <Outlet />
            </div>
          </div>
        </div>
        <Rightside />
      </div>
      <Footer />
    </Fragment>
  );
};
export default MatxLayout;
