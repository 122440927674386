import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import UserService from "../../../../services/users.service";


export const SessionTable = (props) => {
    const { id } = useParams();
    const { state } = useLocation()
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState("");
    const [search, setSearch] = useState("");
    const navigate = useNavigate()
    const getAllUserData = async () => {
        setIsLoading(true)
        const response = await UserService.getAllSession({
            user_id: id,
            search: search.toLowerCase() || "",
            page: pageNumber,
            limit: dataPerPage
        });
        setIsLoading(false)
        setTotalRows(response?.data.totalCount);
        setData(response?.data.SessionList);
    };

    useEffect(() => {
        getAllUserData();
    }, [search, pageNumber, dataPerPage]);

    const columns = [
        {
            name: <b>FREQUENCY 1</b>,
            selector: row => <div>{Number(parseFloat(row.frequency_1).toFixed(3))}</div>,
            sortable: true,
        },
        {
            name: <b>FREQUENCY 2</b>,
            selector: row => <div>{Number(parseFloat(row.frequency_2).toFixed(3))}</div>,
            sortable: true
        },

        {
            name: <b>FREQUENCY DIFFRENCE</b>,
            selector: row => <div>{Number(parseFloat(row.diff_of_frq).toFixed(3))}</div>,
            sortable: true,
        },
        {
            name: <b>SPECIFY</b>,
            selector: row => <div>{row.specify}</div>,
            sortable: true,
        },
        {
            name: <b>DURATION</b>,
            selector: row => <div>{row.duration}</div>,
            sortable: false,
        },
        {
            name: <b>PRESET CALIBRATION LEFT</b>,
            selector: row => <div>{Number(row.preset_calibration_left)?.toFixed(2)}</div>,
            sortable: false,
        },
        {
            name: <b>PRESET CALIBRATION RIGHT</b>,
            selector: row => <div>{Number(row.preset_calibration_right)?.toFixed(2)}</div>,
            sortable: false,
        },
        {
            name: <b>BEFORE LEVEL</b>,
            selector: row => <div>{row.before_comfort_evolution.split(".")[0]}</div>,
            sortable: false,
        },
        {
            name: <b>AFTER LEVEL</b>,
            selector: row => <div>{row.after_comfort_evolution.split(".")[0]}</div>,
            sortable: false,
        },
        {
            name: <b>SESSION COUNT</b>,
            selector: row => <div>{row.finish_count}</div>,
            sortable: true,
        },
    ];

    const extensions = useMemo(
        () => ({
            filter: true,
        }),
        []
    );

    const handleData = (e) => {
        setSearch(e.target.value);
    };

    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        console.log("🚀 ~ handlePerPageChange ~ newPerPage:", newPerPage)
        setIsLoading(true);
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };

    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };


    return (
        <>
            <div className="page-header2"></div>
            <div className="d-flex align-items-center mb-3">
                <div style={{
                    display: 'flex', marginRight: '13px', alignItems: 'center', cursor: 'pointer', color: 'white',
                    backgroundColor: '#0E0E23',
                    padding: '10px 11px',
                    borderRadius: '100%',
                }} onClick={() => navigate(-1)}> <i className="fas fa-arrow-left" style={{ fontSize: '16px' }}></i></div>
                <div className="w-100">
                    <div style={{
                        fontWeight: 600,
                        fontSize: 'large',
                    }}
                    >{state?.email}</div>
                </div>
            </div >
            <Card className="custom-card overflow-hidden">
                <Card.Body className="pt-3">
                    <div className="responsive">
                        <Card className="custom-card overflow-hidden">
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', }}>
                                    {/* <div style={{ fontWeight: 500, fontSize: 'larger' }}>{state?.email}</div> */}
                                    <div className="align-items-end" style={{ paddingLeft: '0px', paddingRight: '0px', maxWidth: '200px', marginLeft: 'auto' }}>
                                        <div
                                            style={{
                                                justifyContent: "start",
                                                marginRight: "2px",
                                                marginTop: '15px'
                                            }}
                                        >
                                            <input
                                                type="search"
                                                style={{
                                                    border: "1px solid #e8e8f7",
                                                    padding: "0.6rem 0.75rem",
                                                    borderRadius: "7px",
                                                    width: '100%'
                                                }}
                                                value={search}
                                                placeholder="Filter Table"
                                                onChange={(e) => handleData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    {...extensions}
                                    data={data}
                                    columns={columns}
                                    noHeader
                                    persistTableHead
                                    pagination
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                    }}
                                    highlightOnHover
                                    center={true}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                                    paginationPerPage={dataPerPage}
                                    paginationDefaultPage={pageNumber}
                                    onChangeRowsPerPage={(page) => {
                                        handlePerPageChange(page, 1);
                                    }}
                                    onChangePage={handlePageChange}
                                    progressPending={isLoading}
                                    cu
                                    progressComponent={
                                        <CircularProgress
                                            size={30}
                                            style={{ marginTop: "50px" }}
                                        />
                                    }
                                />
                            </div>
                        </Card>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
