import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { SubscriptionTable } from "./subscriptionTable";
import subscriptionService from "../../../../services/subscription.service.js";
import { useFormik } from "formik";
import * as yup from "yup";
import useDialogState from "../../../../hooks/useDialog.js";
import { SubscriptionModel } from "./subscriptionmodel.js";
import { useSnackbar } from "notistack";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";



export function Subscription() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState("");
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [update, setUpdate] = useState(false);
    const [isNormal, setIsNormal] = useState(false)
    const [planType, setPlanType] = useState("normal")
    const { enqueueSnackbar } = useSnackbar();

    const schema = yup.object().shape({
        plan_type: yup.mixed().required("Please select plan type"),
        price: yup.string().required("Please enter price"),
        name: yup.string().required("Please enter name"),
        period_type: isNormal ? yup.mixed().required("Please select period type") : "",
        description: yup.string().required("Please enter description"),
        start_date: isNormal ? "" : yup.mixed().required("Please select start date"),
        exp_date: isNormal ? "" : yup.mixed().required("Please select expiry date"),
    });


    const {
        open: modalShow,
        handleClickOpen: handleDialogOpen,
        handleClose: handleDialogClose,
    } = useDialogState();

    const getAllUserData = async () => {
        setIsLoading(true)
        const response = await subscriptionService.getAllUser({
            // search: search.toLowerCase() || "",
            page: pageNumber,
            limit: dataPerPage
        });
        setIsLoading(false)
        setTotalRows(response?.data.totalPlans);
        setData(response?.data.subscriptionPlanData);
    };

    useEffect(() => {
        getAllUserData();
    }, [search, pageNumber]);

    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        setIsLoading(true);
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };

    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        await getAllUserData();
        setIsLoading(false);
    };

    const handleData = (e) => {
        setSearch(e.target.value);
    };

    const initialValue = {
        plan_type: "",
        price: "",
        period_type: "",
        name: "",
        description: "",
        start_date: new Date().getTime(),
        exp_date: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
    };

    const submit = async (values, action) => {
        const { plan_type, period_type, start_date, exp_date, price, name, description, plan_id } = values;

        const newObj = {
            plan_type: plan_type,
            price: price,
            period_type: planType === 'normal' ? period_type : 'year',
            name: name,
            description: description,
            start_date: Number(start_date),
            exp_date: Number(exp_date),
            plan_id: plan_id
        };

        if (!values.plan_id) {
            try {
                setLoader(true);
                await subscriptionService.creteSubscitpion(newObj);
                setLoader(false);
                handleDialogClose();
                getAllUserData();
                action.resetForm();
                enqueueSnackbar("Add subscription successfully", { variant: "success" });
            } catch (error) {
                console.error("create race error", error);
                setLoader(false);
            }
        } else {
            try {
                setLoader(true);
                await subscriptionService.UpdateSunscription(newObj);
                setLoader(false);
                handleDialogClose();
                getAllUserData();
                action.resetForm();
                enqueueSnackbar("Update subscription successfully", { variant: "success" });
            } catch (error) {
                console.error("create race error", error);
            }
        }
    };

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: submit,
    });

    const handleOpen = async (id) => {
        handleDialogOpen();
        if (id) {
            setUpdate(true);
            const response = await subscriptionService.GetSubscription({
                plan_id: id
            });
            const result = response.data.subscriptionPlanData;
            formik.setFieldValue("plan_type", result.plan_type);
            setIsNormal(result.plan_type === 'normal' ? true : false)
            setPlanType(result.plan_type)
            formik.setFieldValue("plan_id", result._id);
            formik.setFieldValue("price", result.price);
            formik.setFieldValue("period_type", result.period_type);
            formik.setFieldValue("name", result.name);
            formik.setFieldValue("description", result.description);
            formik.setFieldValue("start_date", result.start_date);
            formik.setFieldValue("exp_date", result.exp_date);
        } else {
            setUpdate(false);
            formik.resetForm()
            formik.setFieldValue("start_date", "");
            formik.setFieldValue("exp_date", "");
        }
    };

    const changePeriod = (value) => {
        formik.setFieldValue("period_type", value.value)
    }

    const changePlan = (value) => {
        setIsNormal(value.value === 'normal' ? true : false)
        formik.setFieldValue("plan_type", value.value)
        setPlanType(value.value)
    }

    const startDateChange = (date) => {
        const dateChange = moment(date.target.value).add(5, "hours").add(30, "minutes").valueOf()
        formik.setFieldValue("start_date", dateChange);
    }

    const endDateChange = (date) => {
        const dateChange = moment(date.target.value).add(5, "hours").add(30, "minutes").valueOf()
        formik.setFieldValue("exp_date", dateChange);
    }

    return (
        <>
            <div className="page-header2"></div>
            <div className="row">
                <div className="d-flex justify-content-between align-items-end mb-3  ps-0">
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card m-0">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold pt-1">
                                        Total Subscription Plan
                                    </label>
                                    <h2 className="text-end card-item-icon card-icon">
                                        <i className="fas fa-wallet icon-size float-start text-primary"></i>
                                        <span className="font-weight-bold">{totalRows}</span>
                                    </h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div>
                        <Button className="text-white" onClick={() => handleOpen("")}>
                            <i className="fas fa-plus"></i> &nbsp; Add Subscription
                        </Button>
                    </div>
                </div>
                <SubscriptionTable
                    data={data}
                    totalRows={totalRows}
                    dataPerPage={dataPerPage}
                    handlePerPageChange={handlePerPageChange}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    getAllUser={getAllUserData}
                    handleData={handleData}
                    pageNumber={pageNumber}
                    search={search}
                    handleOpen={handleOpen}
                />
                {modalShow &&
                    <SubscriptionModel
                        show={modalShow}
                        onHide={handleDialogClose}
                        formik={formik}
                        update={update}
                        loader={loader}
                        changePeriod={changePeriod}
                        changePlan={changePlan}
                        startDateChange={startDateChange}
                        endDateChange={endDateChange}
                        isNormal={isNormal}
                        planType={planType}
                    />}
            </div>
        </>
    );
}
