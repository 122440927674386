import serverCall from "../serverCall";

const getAllUser = async (data) => {
  const response = await serverCall.post(
    `/admin/users/deleted-list`, data
  );
  return response;
};
const UserUpdate = async (id) => {
  const response = await serverCall.post(`/admin/users/recover-account?userId=${id}`);
  return response;
};

const DeleteUserService = {
  getAllUser,
  UserUpdate,
};

export default DeleteUserService;
