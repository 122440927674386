import serverCall from "../serverCall";

const getAllUser = async (data) => {
  const response = await serverCall.post(
    `/subscription-plan/all-plan`, data
  );
  return response;
};
const getAllSession = async (data) => {
  const response = await serverCall.post(`session/get`, data);
  return response;
};

const GetSingleUserData = async (page, pagesize, id) => {
  const response = await serverCall.get(
    `/userstory/admin/getByUser?pageNumber=${page}&pageSize=${pagesize}&userId=${id}`
  );
  return response;
};
const UserDelete = async (id) => {
  const response = await serverCall.delete(
    `/user/delete-account/admin?userId=${id}`
  );
  return response;
};
const UpdateSunscription = async (data) => {
  const response = await serverCall.put(`subscription-plan`, data);
  return response;
};
const GetSubscription = async (data) => {
  const response = await serverCall.post(
    `/subscription-plan/get-single`, data
  );
  return response;
};
const creteSubscitpion = async (data) => {
  const response = await serverCall.post(
    `/subscription-plan`, data
  );
  return response;
};
const GetImageList = async (id) => {
  const response = await serverCall.get(
    `/coverimage/admin/getUserImage?userId=${id}`
  );
  return response;
};
const deleteSubscription = async (id) => {
  const response = await serverCall.delete(
    `/subscription-plan/delete/${id}`,
  );
  return response;
};

const subscriptionService = {
  getAllUser,
  GetSingleUserData,
  UserDelete,
  GetSubscription,
  UpdateSunscription,
  getAllSession,
  creteSubscitpion,
  GetImageList,
  deleteSubscription
};

export default subscriptionService;
