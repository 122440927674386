import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import UserService from "../../../../services/users.service";


export const UserTable = (props) => {
  const {
    data,
    totalRows,
    dataPerPage,
    handlePageChange,
    handlePerPageChange,
    isLoading,
    getAllUser,
    handleData,
    pageNumber,
    search,
  } = props;
  const navigate = useNavigate()

  const handlenavigate = (id, email) => {
    navigate(`/dashboard/${id}`, {
      state: {
        email: email,
      },
    })
  }

  const changeSpecial = async (row) => {
    const response = await UserService.SpecialUser(row._id);
    getAllUser()
  }

  const columns = [
    {
      name: "EMAIL",
      selector: row => <div title={row.email}>{row.email}</div>,
      sortable: true,
    },
    {
      name: "FIRST NAME",
      selector: row => <div title={row.first_name}>{row.first_name}</div>,
      sortable: true
    },
    {
      name: "LAST NAME",
      selector: row => <div title={row.last_name}>{row.last_name}</div>,
      sortable: true,
    },
    {
      name: "PHONE NUMBER",
      selector: row => <div title={row.mobile}>{row.mobile}</div>,
      sortable: true,
    },
    {
      name: "CREATION DATE",
      selector: row => <div title={moment(row.createdAt).format('DD-MM-YYYY')}>{moment(row.createdAt).format('DD-MM-YYYY')}</div>,
      sortable: true,
    },
    {
      name: "SPECIAL USER",
      selector: row => (
        <div style={{ marginTop: '-13px' }}>
          <Form.Check type="switch" checked={row.special} onClick={() => changeSpecial(row)} />
        </div>
      ),
      sortable: true,
    },
    {
      name: "ACTIONS",
      sortable: true,
      selector: row => <button className="btn btn-primary btn-sm" onClick={() => handlenavigate(row._id, row.email)}><i className="fas fa-eye me-1"></i>Session</button>
    }
  ];

  const extensions = useMemo(
    () => ({
      filter: true,
    }),
    []
  );

  return (
    <>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div>
                    <div className="align-items-end" style={{ paddingLeft: '0px', paddingRight: '0px', marginBottom: '10px', maxWidth: '200px', marginLeft: 'auto' }}>
                      <div
                        style={{
                          justifyContent: "start",
                          marginRight: "2px",
                          marginTop: '15px'
                        }}
                      >
                        <input
                          type="search"
                          style={{
                            border: "1px solid #e8e8f7",
                            padding: "0.6rem 0.75rem",
                            borderRadius: "7px",
                            width: '100%'
                          }}
                          value={search}
                          placeholder="Email/name"
                          onChange={(e) => handleData(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    {...extensions}
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                    paginationPerPage={dataPerPage}
                    paginationDefaultPage={pageNumber}
                    onChangeRowsPerPage={(page) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    cu
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
