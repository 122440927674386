import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useState } from "react";
import { Card, } from "react-bootstrap";
import DataTable from "react-data-table-component";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { DeleteSubscription } from "./subscriptionmodel";


export const SubscriptionTable = (props) => {
    const {
        data,
        totalRows,
        dataPerPage,
        handlePageChange,
        handlePerPageChange,
        isLoading,
        getAllUser,
        pageNumber,
        handleOpen
    } = props;
    const [modelShow, setModelShow] = useState(false)
    const [id, setId] = useState("")
    const [name, setName] = useState("")

    const handelDeleteModel = (id, name) => {
        setModelShow(true)
        setName(name)
        setId(id)
    }

    console.log('data',data, moment(data[3]?.exp_date).format("YYYY-MM-DD"))

    const handleClose = () => setModelShow(false)

    const columns = [
        {
            name: "NAME",
            selector: row => <div title={row.name}>{row.name}</div>,
            sortable: true,
            width: '150px'
        },
        {
            name: "PLAN",
            selector: row => <div title={row.plan_type}>{row.plan_type}</div>,
            sortable: true,
            width: '200px'
        },
        {
            name: "PRICE",
            selector: row => <div title={row.price}>{row.price}</div>,
            sortable: true,
            width: '120px'
        },
        {
            name: "PERIOD TYPE",
            selector: row => <div title={row.period_type}>{row.period_type}</div>,
            sortable: true,
            width: '190px'
        },
        {
            name: "DESCRIPTION",
            selector: row => <div title={row.description}>{row.description}</div>,
            sortable: false,
            width: '400px'
        },
        {
            name: "START DATE",
            selector: row => <div title={row.plan_type !== "normal" && moment(row.start_date).format('DD-MM-YYYY')}>{row.plan_type !== "normal" && moment(row.start_date).format('DD-MM-YYYY')}</div>,
            sortable: true,
            width: '170px'
        },
        {
            name: "EXPIRY DATE",
            selector: row => <div title={row.plan_type !== "normal" && moment(row.exp_date).format('DD-MM-YYYY')}>{row.plan_type !== "normal" && moment(row.exp_date).format('DD-MM-YYYY')}</div>,
            sortable: true,
            width: '170px'
        },
        {
            name: <b>ACTION</b>,
            selector: (row) => (
                <>
                    <div className="d-flex">
                        <div className="justify-content-center">
                            <button
                                style={{ marginLeft: "10px" }}
                                className="btn btn-primary btn-sm"
                                onClick={() => handleOpen(row._id)}
                            >
                                <i className="fa fa-pencil"></i>
                            </button>
                            <button
                                style={{ marginLeft: "10px" }}
                                className="btn btn-danger btn-sm"
                                onClick={() => handelDeleteModel(row._id, row.name)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </>
            ),
            width: "150px",
        },
    ];

    const extensions = useMemo(
        () => ({
            filter: true,
        }),
        []
    );

    return (
        <>
            <div lg={12} className="w-100">
                <Card className="custom-card overflow-hidden">
                    <Card.Body className="pb-0">
                        <div className="responsive">
                            <Card className="custom-card overflow-hidden">
                                <div>
                                    <div style={{ marginTop: '10px ' }}>
                                    </div>
                                    <DataTable
                                        {...extensions}
                                        data={data}
                                        columns={columns}
                                        noHeader
                                        persistTableHead
                                        pagination
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                        highlightOnHover
                                        center={true}
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationRowsPerPageOptions={[5, 10, 50, 100]}
                                        paginationPerPage={dataPerPage}
                                        paginationDefaultPage={pageNumber}
                                        onChangeRowsPerPage={(page) => {
                                            handlePerPageChange(page, 1);
                                        }}
                                        onChangePage={handlePageChange}
                                        progressPending={isLoading}
                                        cu
                                        progressComponent={
                                            <CircularProgress
                                                size={30}
                                                style={{ marginTop: "50px" }}
                                            />
                                        }
                                    />
                                </div>
                            </Card>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {modelShow && <DeleteSubscription show={modelShow} getAllResult={getAllUser} onHide={handleClose} id={id} name={name} />}
        </>
    );
};
